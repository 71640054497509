import Glide, {Breakpoints, Controls, Swipe} from '@glidejs/glide/dist/glide.modular.esm'
import MobileDetect from 'mobile-detect';

// check for mobile
const md = new MobileDetect(navigator.userAgent);
//const isMobile = Math.min(window.screen.width, window.screen.height) < 993 || navigator.userAgent.indexOf("Mobi") > -1;
const isMobile = md.mobile();

// 1.25 mutator to left
const mutator = function (Glide, Components, Events) {
    return {
        modify(translate) {
            // First slide
            if (Glide.index === 0) {
                // Move slide 20 pixels from left
                return translate;
                // Last slide
            } else if (Glide.index === Components.Sizes.length - 1) {
                // Move slide 20 pixels from right
                return translate - (Components.Sizes.width - Components.Sizes.slideWidth) + 24;
                // Other slides
            } else {
                // Center slide
                return translate - (Components.Sizes.width - Components.Sizes.slideWidth) / 2;
            }
        }
    };
};

const HideOutermostArrows = function (Glide, Components, Events) {
    return {
        mount() {
            // Only in effect when rewinding is disabled
            // if (Glide.settings.rewind) {
            //     return
            // }

            Glide.on(['mount.after', 'run'], () => {
                // Filter out arrows_control
                for (let controlItem of Components.Controls.items) {
                    if (controlItem.className !== 'glide__arrows') {
                        continue
                    }

                    // get the arrows
                    const left = controlItem.querySelector('.glide__arrow--prev');
                    const right = controlItem.querySelector('.glide__arrow--next');

                    if (left) {
                        // first slide
                        if (Glide.index === 0) {
                            left.style.display = 'none'
                        } else {
                            left.style.display = 'block'
                        }

                    }
                    if (right) {
                        // last slide
                        if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                            right.style.display = 'none'
                        } else {
                            right.style.display = 'block'
                        }
                    }
                }
            })
        }
    }
}

// slider definitions
let slider_section_newest = new Glide('#newest', {
    gap: 14,
    focusAt: 0,
    perView: 1.25,
    rewind: false,
    type: 'slider'
});

let slider_section_opinions = new Glide('#opinions', {
    gap: 0,
    perView: 4,
    focusAt: 0,
    perTouch: 1,
    type: 'carousel',
});

let slider_section_videos = new Glide('#videos', {
    gap: 30,
    focusAt: 0,
    perView: 3,
    rewind: false,
    type: 'slider',
    breakpoints: {
        993: {
            perView: 1.25,
            gap: 14
        }
    }
});

let slider_section_abroad = new Glide('#abroad', {
    gap: 40,
    focusAt: 0,
    perView: 3,
    rewind: false,
    type: 'slider',
    breakpoints: {
        993: {
            gap: 20,
            perView: 1.25
        }
    }
});

let slider_section_publishing = new Glide('#publishing', {
    gap: 85,
    perView: 3,
    focusAt: 0,
    perTouch: 1,
    type: 'carousel',
    breakpoints: {
        993: {
            gap: 24,
            type: 'slider',
            perView: 1.25
        }
    }
});

let slider_section_projects = new Glide('#projects', {
    gap: 66,
    perView: 3,
    focusAt: 0,
    perTouch: 1,
    type: 'carousel',
});

let slider_read_more_widget = new Glide('#readMoreWidget', {
    gap: 0,
    perView: 2,
    focusAt: 0,
    perTouch: 1,
    type: 'carousel',
    breakpoints: {
        993: {
            type: 'slider',
            perView: 1.25
        }
    }
});

let slider_more_videos = new Glide('#moreVideos', {
    gap: 14,
    focusAt: 0,
    perView: 3,
    rewind: false,
    type: 'slider',
    breakpoints: {
        993: {
            perView: 1.25,
        }
    }
});


function loadFrontPageSliders() {
// mount sliders
    if (isMobile) {
        if (document.getElementById("newest")) {
            slider_section_newest.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
        if (document.getElementById("opinions")) {
            slider_section_opinions.destroy();
        }
        if (document.getElementById("videos")) {
            slider_section_videos.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
        if (document.getElementById("abroad")) {
            slider_section_abroad.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
        if (document.getElementById("publishing")) {
            slider_section_publishing.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
        if (document.getElementById("projects")) {
            slider_section_projects.destroy();
        }
        // article
        if (document.getElementById("readMoreWidget")) {
            slider_read_more_widget.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
        // more videos
        if (document.getElementById("moreVideos")) {
            slider_more_videos.mutate([mutator]).mount({Controls, Breakpoints, Swipe});
        }
    } else {
        if (document.getElementById("newest")) {
            slider_section_newest.destroy();
        }
        if (document.getElementById("opinions")) {
            slider_section_opinions.mount({Controls, Breakpoints, Swipe, HideOutermostArrows});
        }
        if (document.getElementById("videos")) {
            slider_section_videos.mount({Breakpoints});
        }
        if (document.getElementById("abroad")) {
            slider_section_abroad.mount({Breakpoints});
        }
        if (document.getElementById("publishing")) {
            slider_section_publishing.mount({Controls, Breakpoints, Swipe, HideOutermostArrows});
        }
        if (document.getElementById("projects")) {
            slider_section_projects.mount({Controls, Breakpoints, Swipe, HideOutermostArrows});
        }
        // article
        if (document.getElementById("readMoreWidget")) {
            slider_read_more_widget.mount({Controls, Breakpoints, Swipe, HideOutermostArrows});
        }
        // more videos
        if (document.getElementById("moreVideos")) {
            slider_more_videos.mount({Breakpoints});
        }
    }
}

window.addEventListener('DOMContentLoaded', loadFrontPageSliders);
