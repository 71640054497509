// Import our custom CSS
import '../scss/styles.scss'
// Import all of Bootstrap's JS
import './sliders.js';
import {copyToClipboard} from './clipboard.js';
import {toggleButton} from './toggle.js';
import {closeAllOffcanvas, focusOffcanvasSearch} from './offcanvas.js';

window.copyToClipboard = copyToClipboard;
window.scrollToTop = function scrollToTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
};

function pageMenu() {
    let lastScrollTop;
    const menuDesktop = document.querySelector('.nav-desktop');

    if (menuDesktop) {
        window.addEventListener('scroll', () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > lastScrollTop) {
                menuDesktop.style.top = '-' + menuDesktop.offsetHeight + 'px'; // negative value of element height
            } else {
                menuDesktop.style.top = '0';
            }

            lastScrollTop = scrollTop; // new position stored
        });
    }

    const menuMobile = document.querySelector('.nav-mobile');

    if (menuMobile) {
        // window.addEventListener('resize', function () {
        //     menuMobile.style.display = 'none';
        // });

        window.addEventListener('scroll', () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (window.innerWidth < 576 && scrollTop > 20) {
                menuMobile.style.display = 'block';
            } else {
                menuMobile.style.display = 'none';
            }
        });
    }

    window.addEventListener('native.keyboardshow', () => {
        document.querySelector('.nav-mobile').style.bottom = '25px';
    });
    window.addEventListener('native.keyboardhide', () => {
        document.querySelector('.nav-mobile').style.bottom = 0;
    });

    // listen for clicks of other mobile menu items
    document.querySelector('.nav-no-offcanvas').addEventListener('click', function (e) {
        closeAllOffcanvas();
    });

    // listen for clicks of other mobile menu items
    document.querySelector('.nav-search-button').addEventListener('click', function (e) {
        focusOffcanvasSearch();
    });
}

// after dom loaded
document.addEventListener('DOMContentLoaded', () => {
    pageMenu();
    toggleButton('btn-toggle', 'extra-content', 'czytaj opis', 'zwiń opis');
  //  donationBox();
});
