import {Offcanvas} from 'bootstrap';

export function closeAllOffcanvas() {
    document.querySelectorAll('.offcanvas')
        .forEach(offcanvasElement => Offcanvas.getInstance(offcanvasElement)?.hide());
}

export function focusOffcanvasSearch() {
    document.addEventListener('shown.bs.offcanvas', (event) => {
        const offcanvas = event.target;
        const input = offcanvas.querySelector('input');

        if (input) {
            input.focus();
        }
    });
}