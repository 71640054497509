export function toggleButton(toggleButtonClass, extraContentClass, expandText, collapseText) {
    const toggleButton = document.querySelector(`.${toggleButtonClass}`);
    const extraContent = document.querySelector(`.${extraContentClass}`);

    if (toggleButton && extraContent) {
        toggleButton.addEventListener('click', function () {
            if (extraContent.classList.contains('d-none')) {
                extraContent.classList.remove('d-none');
                extraContent.classList.add('d-block');
                toggleButton.textContent = collapseText;
            } else {
                extraContent.classList.remove('d-block');
                extraContent.classList.add('d-none');
                toggleButton.textContent = expandText;
            }
        });
    }
}
